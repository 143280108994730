import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import TxtImg from "../components/TxtImg/TxtImg"
import { Helmet } from "react-helmet"
import Button from "../components/Button/Button"
import Console from "../components/Console/Console"
import Text from "../components/Text/Text"
import { StaticImage } from "gatsby-plugin-image"
import * as HomeStyles from "./index.module.css"

function Home({ data }) {

  return (
    <Layout>
      <Helmet>
        <meta name="description" content="As a Consulting Optometrist, my goal is to provide you with the highest standard of eye care in an environment where you don't feel like you're primarily here to be sold new glasses." />
      </Helmet>
      <section style={{paddingTop: 'var(--space-5)'}}>
        <TxtImg
          imgFirst={true}
          split="third"
          imgSrc={data.frames}
          imgAlt="Traditional trial frame illustration"
          removeShadow={true}
        >
          <h2>Welcome to the practice</h2>
          <h3 style={{color: 'var(--primary-200)', marginBottom: 'var(--space-5)'}}>A private optometry practice specialising in high-quality, clinically-focused eyecare and vision therapy.</h3>
          <Console variant="center">
            <div className={HomeStyles.quote}>
              <p>"As a Consulting Neuro-Optometrist, my goal is to provide you with the highest standard of eye care in an environment where you don’t feel like you’re primarily here to be sold new glasses or contact lenses.</p>
              <p>I offer comprehensive, tailored eye examinations based on your individual needs as a patient."</p>
              <p style={{color: 'var(--primary-300)'}}>- Dr. Christian French, Consulting Optometrist</p>
            </div>
          </Console>
          <Button external={false} type="primarySolid" margin="var(--space-5) 0 0 0" to="/contact">Make an appointment</Button>
        </TxtImg>
      </section>
      <section style={{backgroundColor: 'var(--neutral-900)'}}>
        <div style={{width: 'min(90%, 1200px)', margin: 'auto'}}>
          <Console variant="center">
            <div className={HomeStyles.christianContainer}>
              <StaticImage className={HomeStyles.christianImage} src="../images/christian-entrance.jpg" alt="Dr. Christian French" />
              <div className={HomeStyles.christianText}>
                <h2>About Dr. Christian French</h2>
                <p>My passion for optometry runs through everything that I do. Everyone’s eyes are different and I am a firm believer that getting it right cannot be rushed. I also think my patients should leave the practice with a better understanding of their own eyes and I will always strive to answer whatever questions you may have.</p>
                <p>I look forward to seeing you for an appointment.</p>
                <Button external={false} type="primaryOutline" margin="var(--space-4) 0 var(--space-4) 0" to="/about">Learn more about me</Button>
              </div>
            </div>
          </Console>
        </div>
      </section>
      <section>
        <Text>
          <h2>Services</h2>
          <p>Sight is one of our most precious senses and it is the one that most of us readily admit we could not do without. An eye examination is not just about how well you can see, and it is not something that only people who wear glasses or contact lenses should have. Our eyes can show so much about our general health and wellbeing and a thorough check of your ocular health is very important.</p>
          <p>My ethos is to take the time to give you a thorough examination and ensure that your eyes are as healthy as possible. Eye exams have become intrinsically linked with buying glasses, but my practice breaks away from that concept to focus solely on eye health; allowing you to sort your glasses out (if needed) at a later date.</p>
          <p>In addition to routine eye examinations and contact lens fittings and check-ups, I also happily take on complex cases and second-opinions; these often just require a little more time and ensuring the patient understands what is going on with their eyes.</p>
          <h3>Find out more about what I do:</h3>
          <ul>
            <li><Link to="/services/eye-examinations/">Eye examinations</Link></li>
            <li><Link to="/services/contact-lenses/">Contact lenses</Link></li>
            <li><Link to="/services/neuro-optometry/">Neuro-optometry</Link></li>
          </ul>
        </Text>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    frames: file(relativePath: {eq: "trial-frame.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: WEBP
        )
      }
    }
    christian: file(relativePath: {eq: "christian-entrance.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: WEBP
        )
      }
    }
  }
`

export default Home